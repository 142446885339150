<template>
  <section class="alinha-centro">
    <NotificationMessenger ref="notification" :message="notificationMessage" />
    <div>
      <div>
        <span style="font-size: x-large">Gráfico de eixos </span>
        <i
          title="Eixo X: leste-oeste
Eixo Y: norte-sul
Eixo Z: frente-trás"
          style="align-self: self-end"
          class="icone-info"
        ></i>
      </div>

      <div class="linha margem">
        <div class="coluna alinha-centro">
          <h3>Data</h3>
          <input
            type="date"
            class="timeBox"
            v-model="date"
            style="width: fit-content"
          />
        </div>

        <div class="coluna ">
          <h3>Intervalo de busca</h3>
          <div class="linha alinha-v">
            De
            <input type="time" v-model="horaInicio" />
            até
            <input type="time" v-model="horaFim" />
            <button @click="getAccData">Buscar</button>
          </div>
        </div>
      </div>
    </div>

    <div
      style="width: 95%; margin: auto"
      v-if="dataLoaded && labels.length > 0"
    >
      <div style="overflow-x: scroll">
        <div
          style="position: relative; max-height: 750px; min-height: 600px"
          :style="`width: ${xAxisData.length * 20}px`"
        >
          <LineChart :legend-alignment="'start'" :chart-data-prop="chartData" />
        </div>
      </div>
    </div>

    <div v-if="!dataLoaded">Selecione um período para busca</div>

    <div v-if="dataLoaded && labels.length == 0">Nenhum dado encontrado</div>
  </section>
</template>

<script>
import AccelerometerDataSource from "@/data/datasource/AccelerometerDataSource";
import LineChart from "../components/LineChart.vue";
import NotificationMessenger from "@/components/NotificationMessenger.vue";
import { DateRangeModel } from "@/data/models/DateRangeModel";

const accDataSource = new AccelerometerDataSource();

export default {
  name: "app",
  components: { LineChart, NotificationMessenger },
  data() {
    return {
      date: new Date().toISOString().slice(0, 10),
      horaInicio: "00:00",
      horaFim: "23:59",
      device: "",
      notificationMessage: "",
      dataLoaded: false,
      chartData: [],
      labels: [],
      xAxisData: [],
      yAxisData: [],
      zAxisData: [],
    };
  },
  created() {
    this.device = JSON.parse(sessionStorage.getItem("dispositivoSelecionado"));
  },
  methods: {
    getAccData: async function () {
      if (!(this.date && this.horaFim && this.horaInicio)) {
        this.notificationMessage = "Preencha todos os campos";
        this.$refs.notification.show();
        return;
      }

      let accData = [];
      const requestDate = new DateRangeModel(
        this.date,
        this.date,
        this.horaInicio,
        this.horaFim
      );

      accData = await accDataSource.getAccData(
        this.device.id,
        requestDate.toJson()
      );

      if (accData.length == 0) {
        this.notificationMessage = "Nenhum dado encontrado";
        this.$refs.notification.show();
      }

      this.labels = [];
      this.xAxisData = [];
      this.yAxisData = [];
      this.zAxisData = [];

      accData.forEach(this.extractAxies);

      this.chartData = {
        labels: this.labels,
        datasets: [
          {
            label: "Eixo X",
            data: this.xAxisData,
            backgroundColor: "red",
            borderColor: "red",
          },
          {
            label: "Eixo Y",
            data: this.yAxisData,
            backgroundColor: "green",
            borderColor: "green",
          },
          {
            label: "Eixo Z",
            data: this.zAxisData,
            backgroundColor: "blue",
            borderColor: "blue",
          },
        ],
      };
      this.dataLoaded = true;
    },
    extractAxies: function (accDataModel) {
      this.xAxisData.push(accDataModel.xAxis);
      this.yAxisData.push(accDataModel.yAxis);
      this.zAxisData.push(accDataModel.zAxis);
      this.labels.push(accDataModel.timeStamp.substr(11, 8));
    },
  },
};
</script>
