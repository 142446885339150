import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import PainelDispositivoView from "@/views/PainelDispositivoView.vue";
import { sso } from "roboflex-thalamus-sso-lib";
import { getPermissao } from "@/services/permissaoService";

export class RoutesName {
    static home = "home";
    static painelDispositivo = "painelDispositivo";
}

function sendUserToPortal() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://portal.thalamus.ind.br/#/";
  } else {
    window.location.href = "http://portal-homologacao.thalamus.ind.br/#/";
  }
}

// valida se esta logado
async function guardMyroute(to, from, next) {
    if (sso.validarSessao() && await hasPermission()) {
        next();
    } else {
        sendUserToPortal();
    }
}

async function hasPermission(){
    const permissoes = await getPermissao();
    // Id da funcionalidade necessária pra acessar o módulo como um todo
    const funcionalidadeNecessaria = 64;
    return permissoes.includes(funcionalidadeNecessaria);
}

// valida se tem permissao
// async function guardPermissao(to, from, next, redirectToLogin = false) {
//     const permissoes = await getPermissao();
//     const funcionalidadeNecessaria = to.meta.funcionalidadeId;
//     if (funcionalidadeNecessaria && !permissoes.includes(funcionalidadeNecessaria)) {
//         if (redirectToLogin) {
//             sendUserToPortal();
//         } else {
//             next({ name: "home" });
//         }
//     } else {
//         next();
//     }
// }

const routes = [
    {
        path: "/",
        name: RoutesName.home,
        component: HomeView,
    },
    {
        path: "/painelDispositivo",
        name: RoutesName.painelDispositivo,
        component: PainelDispositivoView,
    },
];

const router = createRouter({
    mode: "history",
    history: createWebHistory(),
    routes,
});

router.beforeEach(guardMyroute);

export default router;
