export class DeviceModel {
  constructor(
    id,
    deviceCode,
    brand,
    model,
    batteryHealth,
    batteryStatus,
  ) {
    this.id = id;
    this.deviceCode = deviceCode;
    this.brand = brand;
    this.model = model;
    this.batteryHealth = batteryHealth;
    this.batteryStatus = batteryStatus;
  }

  toJson(){
    var mapModel = {
      id: this.id,
      deviceCode: this.deviceCode,
      brand: this.brand,
      model: this.model,
      batteryHealth: this.batteryHealth,
      batteryStatus: this.batteryStatus,
    }
    return JSON.stringify(mapModel)
  }

  static fromJson(json) {
    return new DeviceModel(
      json.id,
      json.dispositivo_cod,
      json.marca,
      json.modelo,
      json.bateriaSaude_nome,
      json.bateriaStatus_nome,
    );
  }
}
