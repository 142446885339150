<template>
  <section class="alinha-centro">
    <NotificationMessenger ref="notification" :message="notificationMessage" />

    <div>
      <div class="linha margem">
        <div class="coluna alinha-centro">
          <h3>Data</h3>
          <div class="linha">
            <input type="date" v-model="dataInicio" />
            <input type="date" v-model="dataFim" />
          </div>
        </div>
        <div class="coluna alinha-centro">
          <h3>Intervalo de busca</h3>
          <div class="linha alinha-v">
            <div>De</div>
            <input type="time" v-model="horaInicio" />
            até
            <input type="time" v-model="horaFim" />
            <!-- Botão de buscar -->
            <button @click="getData">Buscar</button>
            <!-- Download de relatório -->
            <div class="m-icone">
              <a class="icone-download" style="height: fit-content; transform: scale(1.5)" @click="downloadData" data-bs-toggle="tooltip" title="Clique para baixar relatório"></a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isDataLoaded">Selecione um período para busca</div>
      <div v-if="isDataLoaded && labels.length == 0">Nenhum dado encontrado</div>
      <div v-if="isDataLoaded && labels.length > 0">
        <div class="grid-2">
          <!-- Gráfico de nível de bateria -->
          <div class="bloco">
            <h3 class="margem">Nível de bateria (%)</h3>
            <div>
              <LineChart class="grafico" :display-legend="false" :chart-data-prop="batteryLevelChart" />
            </div>
            <div class="grid-2 margem">
              <div class="bloco">
                <div class="fonte-fraca">Saúde da bateria</div>
                {{ device.batteryHealth }}
              </div>
              <div class="bloco">
                <div class="fonte-fraca">Último Status</div>
                {{ device.batteryStatus }}
              </div>
            </div>
          </div>
          <!-- Gráfico de corrente -->
          <div class="bloco">
            <h3 class="margem">Corrente (v)</h3>
            <div>
              <LineChart class="grafico" :display-legend="false" :chart-data-prop="voltageChart" />
            </div>
          </div>
          <!-- Gráfico de temperatura -->
          <div class="bloco">
            <h3 class="margem">Temperatura (°C)</h3>
            <div>
              <LineChart class="grafico" v-if="isDataLoaded" :display-legend="false" :chart-data-prop="temperatureChart" />
            </div>
          </div>
          <!-- Gráfico de energia -->
          <div class="bloco">
            <h3 class="margem">Energia instântanea (ma)</h3>
            <div>
              <LineChart class="grafico" v-if="isDataLoaded" :chart-data-prop="energyChart" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LineChart from "@/components/LineChart.vue";
import NotificationMessenger from "@/components/NotificationMessenger.vue";
import BatteryDataSource from "@/data/datasource/BatteryDataSource";
import { DateRangeModel } from "@/data/models/DateRangeModel";

const batteryDataSource = new BatteryDataSource();

export default {
  data() {
    return {
      notificationMessage: "",
      device: "",
      dataInicio: new Date().toISOString().slice(0, 10),
      dataFim: new Date().toISOString().slice(0, 10),
      horaInicio: "00:00",
      horaFim: "23:59",
      isDataLoaded: false,
      labels: [],
      batteryLevelData: [],
      temperatureData: [],
      currentMaEnergyData: [],
      averageMaEnergyData: [],
      voltageData: [],
      batteryLevelChart: [],
      temperatureChart: [],
      energyChart: [],
      voltageChart: [],
    };
  },
  components: {
    NotificationMessenger,
    LineChart,
  },
  methods: {
    getData: async function () {
      if (!this.isDateRangeFilled()) {
        return;
      }

      const requestDate = new DateRangeModel(this.dataInicio, this.dataFim, this.horaInicio, this.horaFim);
      const batteryDataList = await batteryDataSource.getBatteryData(this.device.id, requestDate);

      if (batteryDataList.length == 0) {
        this.notificationMessage = "Nenhum dado encontrado";
        this.$refs.notification.show();
      }

      this.labels = [];
      this.batteryLevelData = [];
      this.temperatureData = [];
      this.currentMaEnergyData = [];
      this.averageMaEnergyData = [];
      this.voltageData = [];

      batteryDataList.forEach(this.extractData);
      this.assignDataToChart();
      this.isDataLoaded = true;

      console.log(this.temperatureChart);
    },

    downloadData: async function () {
      if (!this.isDateRangeFilled()) {
        return;
      }

      const requestDate = new DateRangeModel(this.dataInicio, this.dataFim, this.horaInicio, this.horaFim);
      var response = await batteryDataSource.downloadData(this.device.id, requestDate);

      // Cria um link temporário
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      link.download = `Relatório_dispositivo_id${this.device.id}.xlsx`;
      0,
        // Adiciona o link ao documento
        document.body.appendChild(link);

      // Dispara o clique no link para iniciar o download
      link.click();

      // Remove o link do documento
      document.body.removeChild(link);
    },

    isDateRangeFilled: function () {
      if (!(this.dataInicio && this.dataFim && this.horaFim && this.horaInicio)) {
        this.notificationMessage = "Preencha todos os campos";
        this.$refs.notification.show();
        return false;
      } else {
        return true;
      }
    },

    extractData: function (data) {
      this.batteryLevelData.push(data.batteryLevel);
      this.voltageData.push(data.voltage);
      this.temperatureData.push(data.temperature);
      this.currentMaEnergyData.push(data.currentEnergyMA);
      this.averageMaEnergyData.push(data.averageEnergyMA);
      data.timeStamp = new Date(data.timeStamp).toLocaleString();
      this.labels.push(`${data.timeStamp.substr(0, 5)} ${data.timeStamp.substr(11, 6)}`);
    },
    assignDataToChart: function () {
      this.batteryLevelChart = {
        labels: this.labels,
        datasets: [
          {
            label: "Nível de bateria",
            data: this.batteryLevelData,
            backgroundColor: "black",
            borderColor: "black",
          },
        ],
      };

      this.temperatureChart = {
        labels: this.labels,
        datasets: [
          {
            label: "Temperatura °C",
            data: this.temperatureData,
            backgroundColor: "black",
            borderColor: "black",
          },
        ],
      };

      this.energyChart = {
        labels: this.labels,
        datasets: [
          {
            label: "Energia instantânea (mA)",
            data: this.currentMaEnergyData,
            backgroundColor: "black",
            borderColor: "black",
          },
          {
            label: "Energia média (mA)",
            data: this.averageMaEnergyData,
            backgroundColor: "red",
            borderColor: "red",
          },
        ],
      };

      this.voltageChart = {
        labels: this.labels,
        datasets: [
          {
            label: "Corrente (v)",
            data: this.voltageData,
            backgroundColor: "black",
            borderColor: "black",
          },
        ],
      };
    },
  },
  created() {
    this.device = JSON.parse(sessionStorage.getItem("dispositivoSelecionado"));
  },
};
</script>

<style scoped>
.grafico {
  height: 220px;
}
</style>
