export class AccelerometerModel {
    constructor(id, xAxis, yAxis, zAxis, timeStamp){
        this.id = id;
        this.xAxis = xAxis;
        this.yAxis = yAxis;
        this.zAxis = zAxis;
        this.timeStamp = timeStamp
    }

    static fromJson(json){
        const id = json.id;
        const xAxis = json.eixo_x;
        const yAxis = json.eixo_y;
        const zAxis = json.eixo_z;
        const timeStamp = json.dtRegistro;
        return new AccelerometerModel(id, xAxis, yAxis, zAxis, timeStamp)
    }
}