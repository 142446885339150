<template>
    <aside>
      <div class="titulo margem efeito">
        <div class="m-icone">
          <a
            href="#"
            class="icone-menu"
            title="Menu"
            onclick="document.getElementById('main').classList.toggle('esconde');return false"
          ></a>
        </div>
      </div>
      <div class="separador margem">
        <div class="m-icone">
          <a class="icone-smartphone ativo" title="Monitoramento"></a>
        </div>
      </div>

      <nav class="nav-maior separador none">
        <a href="#" class="icone-smartphone ativo">Monitoramento</a>
      </nav>
      <nav class="nav-maior separador">
        <a @click="pushTo('home')" class="ativo">Monitoramento</a>
      </nav>
    </aside>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    pushTo(routeName) {
      this.$router.push({ name: routeName });
    },
  },
};
</script>
