/* eslint-disable */
import api from "@/services/api";
import { BatteryDataModel } from "../models/BatteryDataModel";
export default class BatteryDataSource {
  async getBatteryData(deviceId, dtParaBusca) {
    const dataList = [];

    try {
      const response = await api.post(`monitor-bateria/${deviceId}`, {
        dtInicio: dtParaBusca.dtStart + " " + dtParaBusca.hourStart,
        dtFim: dtParaBusca.dtEnd + " " + dtParaBusca.hourEnd,
      });

      console.log({
        dtInicio: dtParaBusca.dtStart + " " + dtParaBusca.hourStart,
        dtFim: dtParaBusca.dtEnd + " " + dtParaBusca.hourEnd,
      })
      response.data.forEach(addToList);
    } catch {
      return [];
    }

    function addToList(data) {
      dataList.push(BatteryDataModel.fromJson(data));
    }
    return dataList;
  }

  async downloadData(deviceId, dtParaBusca) {
    const response = await api.post(
      "relatorio/monitorar-bateria",
      {
        dispositivo_id: deviceId,
        dtInicio: dtParaBusca.dtStart + " " + dtParaBusca.hourStart,
        dtFim: dtParaBusca.dtEnd + " " + dtParaBusca.hourEnd,
      },
      { responseType: "arraybuffer" }
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return blob;
  }
}
