import api from "@/services/api";
import { DeviceModel } from "../models/DeviceModel";

export default class DeviceDataSource {
  async getDevices() {
    var response = await api.get("dispositivo");
    var finalList = [];

    response.data.forEach((element) => {
      finalList.push(DeviceModel.fromJson(element));
    });
    return finalList;
  }
}
