export class DateRangeModel {
  constructor(dtStart, dtEnd, hourStart, hourEnd) {
    this.dtStart = dtStart;
    this.dtEnd = dtEnd ?? dtStart;
    this.hourStart = hourStart;
    this.hourEnd = hourEnd;
  }

  toJson() {
    return {
      dtInicio: this.dtStart + ' ' + this.hourStart,
      dtFim: this.dtEnd + ' ' + this.hourEnd,
    };
  }
}
