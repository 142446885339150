export class BatteryDataModel {
  constructor({
    id,
    deviceId,
    isBatteryLow,
    batteryScale,
    batteryLevel,
    isBatteryPresent,
    batteryHealthCode,
    batteryHealthName,
    batteryStatusCode,
    batteryStatusName,
    chargingTypeCode,
    chargingTypeName,
    isCharging,
    timeStamp,
    currentEnergyMA,
    currentEnergyNW,
    averageEnergyMA,
    temperature,
    timeToCharge,
    voltage,
  }) {
    this.id = id;
    this.deviceId = deviceId;
    this.isBatteryLow = isBatteryLow;
    this.batteryScale = batteryScale;
    this.batteryLevel = batteryLevel;
    this.isBatteryPresent = isBatteryPresent;
    this.batteryHealthCode = batteryHealthCode;
    this.batteryHealthName = batteryHealthName;
    this.batteryStatusCode = batteryStatusCode;
    this.batteryStatusName = batteryStatusName;
    this.chargingTypeCode = chargingTypeCode;
    this.chargingTypeName = chargingTypeName;
    this.isCharging = isCharging;
    this.timeStamp = timeStamp;
    this.currentEnergyMA = currentEnergyMA;
    this.currentEnergyNW = currentEnergyNW;
    this.averageEnergyMA = averageEnergyMA;
    this.temperature = temperature;
    this.timeToCharge = timeToCharge;
    this.voltage = voltage;
  }

  static fromJson(json) {
    return new BatteryDataModel({
      id: json.id,
      deviceId: json.dispositivo_id,
      isBatteryLow: json.bateriaBaixa,
      batteryScale: json.bateriaEscala,
      batteryLevel: json.bateriaNivel,
      isBatteryPresent: json.bateriaPresente,
      batteryHealthCode: json.bateriaSaude_cod,
      batteryHealthName: json.bateriaSaude_nome,
      batteryStatusCode: json.bateriaStatus_cod,
      batteryStatusName: json.bateriaStatus_nome,
      chargingTypeCode: json.carregamentoTipo_cod,
      chargingTypeName: json.carregamentoTipo_nome,
      isCharging: json.carregando,
      currentEnergyMA: json.energiaAtual_ma,
      currentEnergyNW: json.energiaAtual_nw,
      averageEnergyMA: json.energiaMedia_ma,
      temperature: json.temperatura,
      timeToCharge: json.tempoParaCarga,
      voltage: json.voltagem,
      timeStamp: json.created_at
    });
  }
}
