import Cookies from "js-cookie";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

function sendUserToPortal() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://portal.thalamus.ind.br/#/";
  } else {
    window.location.href = "http://portal-homologacao.thalamus.ind.br/#/";
  }
}

function sendUserToLogin() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://thalamus.ind.br/#/login";
  } else {
    window.location.href = "http://homologacao.thalamus.ind.br/#/login";
  }
}

api.interceptors.request.use(
  (config) => {
    const session = Cookies.get("thalamusSession");
    if (session) {
      try {
        // remove o object do início
        const decodedSession = decodeURIComponent(session);
        const jsonString = decodedSession.replace(/^object=/, "");
        const sessionObject = JSON.parse(jsonString);

        const token = sessionObject.token;

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // sem token
          sendUserToLogin();
          return Promise.reject(new Error("Token não encontrado."));
        }
      } catch (e) {
        // token inválido
        Cookies.remove("thalamusSession");
        sendUserToLogin();
        return Promise.reject(new Error("Erro ao ler o token."));
      }
    } else {
      // Usuário não autenticado
      sendUserToLogin();
      return Promise.reject(new Error("Usuário não autenticado."));
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Resposta 401
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove("thalamusSession");
      sendUserToPortal();
    }
    return Promise.reject(error);
  }
);

export default api;
